import { createContext, useContext, useReducer, useEffect } from 'react'
import { sharedConst } from '../utils/action.constant'

const DeepStreamContext = createContext()

export const useDeepStreamContext = () => useContext(DeepStreamContext)

const dsReducer = (state, action) => {
  switch (action.type) {
    case sharedConst.DS_RECORD:
      return {
        ...state,
        ds: action.ds,
      }
    default:
      return state
  }
}

const initialState = {
  ds: null,
}

export const DeepStreamContextProvider = ({ ds, children }) => {
  const [state, dispatch] = useReducer(dsReducer, initialState)
  useEffect(() => {
    dispatch({ type: sharedConst.DS_RECORD, ds: ds })
  }, [ds])

  return (
    <DeepStreamContext.Provider value={{ state, dispatch }}>
      {children}
    </DeepStreamContext.Provider>
  )
}
