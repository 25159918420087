import { createContext, useContext, useReducer } from 'react'
import { sharedConst } from '../utils/action.constant'
import { timelineOptions } from '../components/timeline/util'
import { useLanguageContext } from './locale.context'
import React, { useEffect } from 'react'

const SharedContext = createContext()

export const useSharedContext = () => useContext(SharedContext)

const sharedReducer = (state, action) => {
  switch (action.type) {
    case sharedConst.ACTVITY_SELECTED:
      return {
        ...state,
        activity: action.payload,
      }
    case sharedConst.ACTIVITY_TYPE_DETAILS:
      return {
        ...state,
        activityTypeDetails: action.payload,
      }

    case sharedConst.RESET_TO_TIMELINE:
      return {
        ...state,
        openTeamCollab: action.openTeamCollab,
      }
    case sharedConst.REFRESH_TIMELINE:
      return {
        ...state,
        isRefreshTimeline: true,
      }
    case sharedConst.RESET_REFRESH_TIMELINE:
      return {
        ...state,
        isRefreshTimeline: false,
      }
    case sharedConst.REFRESH_LISTING:
      return {
        ...state,
        isReloadList: true,
      }
    case sharedConst.RESET_REFRESH_LISTING:
      return {
        ...state,
        isReloadList: false,
      }
    case sharedConst.CLEAR_ACTIVITY_SELECTION:
      return {
        ...state,
        activity: null,
      }
    case sharedConst.FETCH_FORM_FIELDS_LIST:
      return {
        ...state,
        fieldList: action.fieldList,
      }
    case sharedConst.REFRESH_SELECTED_ACTIVITY:
      return {
        ...state,
        refreshActivity: action.refreshActivity,
      }
    case sharedConst.SET_SELECTED_QUEUE:
      return {
        ...state,
        selectedQueue: action.queue,
        queue: action.queue,
      }
    case sharedConst.UPDATE_FORM_FIELDS_LIST:
      return {
        ...state,
        fieldList: action.fieldList,
      }
    case sharedConst.SET_ACTION_PANEL_VIEW:
      return {
        ...state,
        view: action.view,
      }
    case sharedConst.SET_SELECTED_FILTER:
      return {
        ...state,
        filter: action.filter,
      }
    case sharedConst.REFRESH_BROADCAST_COUNT:
      return {
        ...state,
        refreshBroadCast: action.refreshBroadCast,
      }
    case sharedConst.TIME_LINE_FILTER:
      localStorage.setItem('timelineFilter', JSON.stringify(action.filterValue))
      let filterValue = localStorage.getItem('timelineFilter')
      return {
        ...state,
        timelineFilter: JSON.parse(filterValue),
      }
    case sharedConst.INITIAL_TIME_LINE_FILTER:
      let value = localStorage.getItem('timelineFilter')
      return {
        ...state,
        timelineFilter: JSON.parse(value) || timelineOptions[1],
      }
    case sharedConst.SET_PARTICIPANT_ACCESS_ID:
      return {
        ...state,
        activityParticipantId: action.accessID,
      }
    case sharedConst.REFRESH_CHAT_COUNT:
      return {
        ...state,
        isRefreshChat: !state.isRefreshChat,
      }
    case sharedConst.REFRESH_CHAT_LIST:
      return {
        ...state,
        refreshChatList: !state.refreshChatList,
      }
    case sharedConst.SELECTED_CHAT_PROFILE:
      return {
        ...state,
        chatSelectedProfile: action.selectedValue,
      }
    case sharedConst.ASSIGN_REPORTING_MANAGER_ACCESS:
      if (action.accessType === 1) {
        return {
          ...state,
          isReportingManagerOwner: true,
          isReportingManagerLead: false,
        }
      } else if (action.accessType === 2) {
        return {
          ...state,
          isReportingManagerOwner: false,
          isReportingManagerLead: true,
        }
      } else {
        return {
          ...state,
          isReportingManagerOwner: false,
          isReportingManagerLead: false,
        }
      }
    case sharedConst.REFRESH_DOC_REPO:
      return {
        ...state,
        isRefreshDocRepo: !state.isRefreshDocRepo,
      }
    case sharedConst.SHOW_LOADER:
      return {
        ...state,
        isShowLoader: true,
        random: Math.random().toString(),
      }
    case sharedConst.HIDE_LOADER:
      return {
        ...state,
        isShowLoader: false,
        random: Math.random().toString(),
      }
    case sharedConst.REFRESH_GANNT_VIEW:
      return {
        ...state,
        isRefreshGantt: !state.isRefreshGantt,
      }
    case sharedConst.FORM_ONFLY_ADD_DATA:
      return {
        ...state,
        onFlyAddData: action.payload,
      }
    case sharedConst.FORM_ONFLY_DATA_TYPE_ID:
      return {
        ...state,
        onFlyDataId: action.payload,
      }
    case sharedConst.FORM_ONFLY_FIELD_ID:
      return {
        ...state,
        onFlyFieldId: action.payload,
      }
    case sharedConst.HANDLE_WHATSNEXT:
      return {
        ...state,
        showWhatsNext: action.payload,
        asset_notification_muted: action.asset_notification_muted,
      }
    default:
      return state
  }
}

const initialState = {
  activity: null,
  activityTypeDetails: null,
  activityParticipantId: '',
  isRefreshTimeline: false,
  isRefreshChat: false,
  refreshChatList: false,
  chatSelectedProfile: {},
  fieldList: [],
  timelineFilter:
    (localStorage.getItem('timelineFilter') &&
      JSON.parse(localStorage.getItem('timelineFilter'))) ||
    timelineOptions[1],
  isRefreshDocRepo: true,
  isShowLoader: false,
  random: 0,
  isRefreshGantt: true,
  onFlyAddData: {},
  onFlyDataId: null,
  onFlyFieldId: null,
  showWhatsNext: false,
  asset_notification_muted: 1,
  refreshBroadCast: false,
}

export const SharedContextProvider = ({ children }) => {
  const {
    state: { locale },
  } = useLanguageContext()
  let queue = {
    queue: {
      queue_name: 'My Files',
      queue_id: 'MY_QUEUE',
      isStatic: true,
      icon: '',
      color: 'brand.800',
      flag: 0,
      isStatusFilter: 1,
      title: 'List of My files',
    },
  }
  useEffect(() => {
    let queue = {
      queue: {
        queue_name: locale['My Files'],
        queue_id: 'MY_QUEUE',
        isStatic: true,
        icon: '',
        color: 'brand.800',
        flag: 0,
        isStatusFilter: 1,
        title: locale['List of My files'],
      },
    }
  }, [locale])

  const [state, dispatch] = useReducer(sharedReducer, {
    ...initialState,
    ...queue,
  })

  return (
    <SharedContext.Provider value={{ state, dispatch }}>
      {children}
    </SharedContext.Provider>
  )
}
