import { createContext, useContext, useReducer } from 'react';
import { drilldownConst } from './../utils/action.constant';

const DrilldownContext = createContext();

export const useDrilldownContext = () => useContext(DrilldownContext);

const initialState = {
  drilldownFilterStatus: {},
};

const drilldownReducer = (state, action) => {
  switch (action.type) {
    case drilldownConst.SAVE_FILTER_STATUS:
      return {
        ...state,
        drilldownFilterStatus: action.payload,
      };
    default:
      return state;
  }
};

export const DrilldownContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(drilldownReducer, initialState);

  return (
    <DrilldownContext.Provider value={{ state, dispatch }}>
      {children}
    </DrilldownContext.Provider>
  );
};
