import React, { createContext, useContext, useReducer } from 'react'
import { dashboardConst } from '../utils/action.constant'
//import { changeWidgetDataOnQueryFilters } from '../utils/dashboard.utils'

const DashboardContext = createContext()
export const useDashboardContext = () => useContext(DashboardContext)

const initialState = {
	filterOptions: {},
	queryFilters: {},
	widgetList: [],
	widgetData: {},
	sipReporteeList: [],
	leaderBoardList: [],
	topDownList: [],
	bottomUpList: [],
	reportees: [],
	accounts_view: {},
	activeFilterOption: [],
	toolTipActivity: {},
	toolTipLoader: false,
}

const dashBoardReducer = (state, action) => {
	switch (action.type) {
		case dashboardConst.SET_FILTER_OPTIONS:
			return {
				...state,
				filterOptions: {
					...state.filterOptions,
					[action.key]: action.filter,
				},
			}
		case dashboardConst.UPDATE_QUERY_FILTERS:
			return {
				...state,
				queryFilters: {
					...state.queryFilters,
					[action.filterName]: action.value,
				},
			}
		case dashboardConst.CLEAR_QUERY_FILTERS:
			return {
				...state,
				queryFilters: {},
			}
		case dashboardConst.LOAD_WIDGETS:
			return {
				...state,
				widgetList: action.widgetList,
			}
		case dashboardConst.CLEAR_WIDGETS:
			return {
				...state,
				widgetList: [],
			}
		case dashboardConst.ADD_TOPDOWN:
		case dashboardConst.UPDATE_TOPDOWN:
			return {
				...state,
				topDownList: action.topDown,
			}
		case dashboardConst.ADD_BOTTOMUP:
		case dashboardConst.UPDATE_BOTTOMUP:
			return {
				...state,
				bottomUpList: action.bottomUp,
			}
		case dashboardConst.LOAD_WIDGET_DATA:
			return {
				...state,
				widgetData: action.widgetData,
			}
		// case dashboardConst.UPDATE_WIDGET_DATA:
		// 	const updatedWidgetData = changeWidgetDataOnQueryFilters(
		// 		state.widgetData,
		// 		state.queryFilters
		// 	)
		// 	return {
		// 		...state,
		// 		widgetData: updatedWidgetData,
		// 	}

		case dashboardConst.ADD_SIP_RESOURCE:
			return {
				...state,
				sipReporteeList: [action.reportee],
			}
		case dashboardConst.CLEAR_REPORTEE_STACK:
			return {
				...state,
				sipReporteeList: [],
			}
		case dashboardConst.ADD_LEADERBOARDS:
			return {
				...state,
				leaderBoardList: action.leaderBoards,
			}
		case dashboardConst.SET_SIP_RESOURCE:
			const found = state.sipReporteeList.findIndex(
				asset => asset.asset_id === action.reportee.asset_id
			)
			if (found !== -1) {
				const filteredReporteeList = state.sipReporteeList.slice(0, found + 1)
				return {
					...state,
					sipReporteeList: filteredReporteeList,
				}
			} else {
				return {
					...state,
					sipReporteeList: [...state.sipReporteeList, action.reportee],
				}
			}
		case dashboardConst.REMOVE_SIP_RESOURCE:
			return {
				...state,
				sipReporteeList: [],
			}
		case dashboardConst.ADD_REPORTEES:
			if (action.payload && action.payload.length) {
				state.accounts = []
			}
			return {
				...state,
				reportees: action.payload,
			}
		case dashboardConst.ADD_ACCOUNTS:
			if (action.payload && action.payload.length) {
				state.reportees = []
			}
			return {
				...state,
				accounts: action.payload,
			}
		case dashboardConst.ADD_ACCOUNT_VIEWS:
			return {
				...state,
				accounts_view: prepareAccViewData(action.payload),
			}
		case dashboardConst.ADD_ACTIVE_FILTER_OPTION:
			return {
				...state,
				activeFilterOption: [action.activeFilterOption],
			}
		case dashboardConst.SET_TOOLTIP_LOADER:
			return {
				...state,
				toolTipLoader: action.payload,
			}
		case dashboardConst.SET_TOOLTIP_ACTIVITY:
			return {
				...state,
				toolTipActivity: action.payload,
			}

		default:
			return state
	}
}

function prepareAccViewData(data) {
	let arr = []
	let obj = {}
	if (Array.isArray(data) && data.length) {
		arr = [...data]
		arr.forEach((item, index) => {
			let key = `${item.segment}_${item.account_code}_${item.account_manager}_${item.circle_code}`
			if (obj[key]) {
				obj[key] = {
					...obj[key],
					[item.month]: item,
					[item.quarter]: item,
				}
			} else {
				obj[key] = {
					segment: item.segment,
					account_code: item.account_code,
					account_name: item.account_name,
					account_manager: item.account_manager,
					circle_code: item.circle_code,
					[item.month]: item,
					[item.quarter]: item,
				}
			}
		})
	}
	return obj
}

export function DashboardContextProvider({ children }) {
	const [state, dispatch] = useReducer(dashBoardReducer, initialState)
	return (
		<DashboardContext.Provider value={{ state, dispatch }}>
			{children}
		</DashboardContext.Provider>
	)
}
