/* eslint-disable react-hooks/exhaustive-deps */
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Button,
  ModalFooter,
  ModalHeader,
  Text,
  VStack,
  Flex,
  Checkbox,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Divider,
  useDisclosure,
  Link,
  Stack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../../../context/auth.context'
import { useLanguageContext } from '../../../context/locale.context'
import { format } from 'date-fns'
import {
  useAddTimelineUpdate,
  useMuteAssetNotification,
  useRemoveParticipant,
  useRemoveParticipantAsLead,
  useUpdateCancelRequest,
} from '../../../hooks/activity.hooks'
import { getMessageUniqueId } from '../../../utils/common.util'
import { useSharedContext } from '../../../context/shared.context'
import { sharedConst } from '../../../utils/action.constant'

const WhatNextView = ({
  activity,
  isOpen,
  onClose,
  children,
  onOpenParticipantView,
  participants,
  showDeclineHistory,
  asset_notification_muted,
  onViewLifeCycle,
}) => {
  const {
    activity_id,
    activity_status_name,
    activity_datetime_end_deferred,
    activity_creator_asset_id,
    activity_lead_asset_id,
    activity_type_id,
    activity_type_category_id,
  } = activity

  const {
    state: { locale },
  } = useLanguageContext()

  const {
    state: { authData },
  } = useAuthContext()

  const {
    state: { showWhatsNext },
    dispatch,
  } = useSharedContext()

  const cancelRef = React.useRef()
  const [willDo, setWillDo] = useState(false)
  const [showForms, setShowForms] = useState(false)

  const {
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
    isOpen: isOpenAlert,
  } = useDisclosure()
  const {
    onOpen: onOpenRequest,
    onClose: onCloseRequest,
    isOpen: isOpenRequest,
  } = useDisclosure()

  const { mutate } = useMuteAssetNotification()
  const { mutate: cancelRequest, isLoading: loading } = useUpdateCancelRequest()
  const { mutate: mutateaddTimeLineUpdate } = useAddTimelineUpdate()
  const { mutate: removeMutate } = useRemoveParticipant()
  const { mutate: removeLead } = useRemoveParticipantAsLead()

  const { asset_id: auth_asset_id } = authData
  const role = activity_lead_asset_id === auth_asset_id ? 'lead' : 'creator'

  const muteNotification = flag => {
    let params = {
      activity_id: activity_id,
      asset_notification_muted: flag,
    }
    mutate(params, {
      onSuccess: async res => {
        setTimeout(() => {
          dispatch({
            type: sharedConst.REFRESH_LISTING,
          })
          dispatch({
            type: sharedConst.HANDLE_WHATSNEXT,
            payload: showWhatsNext,
            asset_notification_muted: flag,
          })
        }, 1000)
      },
      onError: async err => console.log(err),
    })
  }
  const buildTimelineEntryParams = participant => {
    const {
      activity_title,
      activity_id,
      activity_type_category_id,
      parent_activity_id,
    } = activity
    const { operating_asset_first_name } = authData
    let activityTimelineJson = {
      content: `"${participant}" has declined to be lead`,
      subject: '',
      mail_body: `"${participant}" has declined to be lead`,
      attachments: [],
      activity_reference: [
        {
          activity_title: '',
          activity_id: '',
        },
      ],
      asset_reference: [],
      form_approval_field_reference: [],
    }
    return {
      activity_id: activity_id,
      activity_type_category_id: activity_type_category_id,
      activity_type_id: activity_type_id,
      activity_parent_id: parent_activity_id,
      activity_timeline_collection: JSON.stringify(activityTimelineJson),
      activity_stream_type_id: 736,
      timeline_stream_type_id: 736,
      // data_entity_inline: JSON.stringify(activityTimelineJson),
      operating_asset_first_name: operating_asset_first_name,
    }
  }

  const onSubmitDecline = () => {
    onCloseAlert()
    const { operating_asset_first_name, asset_id } = authData
    let timelineParams = buildTimelineEntryParams(operating_asset_first_name)
    let declineUser =
      !!participants && participants.length > 0
        ? participants
            .filter(f => f.asset_id === asset_id)
            .reduce((a, s) => {
              return s
            }, {})
        : {}
    mutateaddTimeLineUpdate(
      { ...timelineParams },
      {
        onSuccess: async res => {
          removeLead(
            {
              lead_asset_id: 0,
              activity_id,
            },
            {
              onSuccess: async data => {
                removeMutate(
                  {
                    activity_id,
                    activity_participant_collection: JSON.stringify([
                      {
                        ...declineUser,
                        access_role_id: 0,
                        message_unique_id: getMessageUniqueId(),
                      },
                    ]),
                  },
                  {
                    onSuccess: async data => {
                      onClose()
                      setTimeout(() => {
                        dispatch({
                          type: sharedConst.REFRESH_LISTING,
                        })
                        dispatch({
                          type: sharedConst.CLEAR_ACTIVITY_SELECTION,
                        })
                      }, 1000)
                    },
                    onError: async error => {
                      console.log('remove error', error)
                      onClose()
                    },
                  }
                )
              },
            }
          )
        },
      }
    )
  }
  const onCancelRequest = () => {
    onCloseRequest()
    cancelRequest(
      {
        activity_id,
        activity_type_id,
        activity_type_category_id,
        workflow_activity_id: activity_id,
      },
      {
        onSuccess: async res => {
          onClose()
          setTimeout(() => {
            dispatch({
              type: sharedConst.ACTVITY_SELECTED,
              payload: null,
            })
            dispatch({
              type: sharedConst.REFRESH_LISTING,
            })
          }, 1000)
        },
        onError: async err => console.log(err),
      }
    )
  }
  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius="md"
          bg="white"
          minW="40vw"
          m="auto"
          maxH="600px"
        >
          <ModalHeader display={'flex'} justifyContent={'center'}>
            <Text color={'brand.800'}>
              {showForms ? 'Progress to Next Status' : 'What Next'}
            </Text>
          </ModalHeader>
          <ModalCloseButton zIndex="100" />
          <ModalBody p={0} w="100%" position="relative">
            {!showForms && (
              <VStack px={10} py={5} alignItems={'flex-start'}>
                <Text>
                  Dear <b>{authData?.operating_asset_first_name}</b>,{' '}
                  {role === 'lead'
                    ? `you have been chosen as lead for `
                    : `you are the creator for `}
                  <span
                    style={{ color: localStorage.getItem('color'), textTransform: 'capitalize' }}
                  >
                    {activity.activity_title}
                  </span>
                  ,
                </Text>
                <VStack w={'full'} px={0} pb={5}>
                  <Flex w={'full'}>
                    <Text>
                      Current Status:{' '}
                      <span style={{ color: localStorage.getItem('color') }}>
                        {activity_status_name}
                      </span>
                    </Text>
                  </Flex>
                  <Flex w={'full'}>
                    <Text>
                      {locale['Status Due Date']}:{' '}
                      <span style={{ color: localStorage.getItem('color') }}>
                        {!!activity_datetime_end_deferred
                          ? activity_datetime_end_deferred.includes('date') ||
                            activity_datetime_end_deferred.includes(
                              '0000-00-00'
                            )
                            ? activity_datetime_end_deferred
                            : format(
                                new Date(
                                  activity_datetime_end_deferred
                                    .split(' ')
                                    .join('T')
                                ),
                                'do MMM yyyy'
                              )
                          : ''}
                      </span>
                    </Text>
                  </Flex>
                </VStack>
                <Text>
                  {`Please take one of the following actions to progress further on this`}
                </Text>
              </VStack>
            )}
            {role === 'lead' || showForms ? (
              showForms ? (
                <Stack w={'full'}>
                  <HStack>{children}</HStack>
                </Stack>
              ) : (
                <HStack justifyContent={'flex-end'} mx={10}>
                  <VStack justifyContent={'flex-end'} alignItems={'flex-end'}>
                    <Button
                      borderRadius="sm"
                      size="sm"
                      colorScheme={localStorage.getItem('color')}
                      variant="outline"
                      onClick={() => setShowForms(true)}
                    >
                      Progress to Next Status
                    </Button>
                    <Button
                      borderRadius="sm"
                      size="sm"
                      colorScheme={localStorage.getItem('color')}
                      variant="outline"
                      onClick={() =>
                        muteNotification(asset_notification_muted === 0 ? 1 : 0)
                      }
                    >
                      {asset_notification_muted === 0 ? 'Snooze' : 'Unsnooze'}
                    </Button>
                    <Button
                      borderRadius="sm"
                      size="sm"
                      colorScheme={localStorage.getItem('color')}
                      variant="outline"
                      onClick={onClose}
                    >
                      {'Review'}
                    </Button>
                    <Button
                      borderRadius="sm"
                      size="sm"
                      colorScheme={localStorage.getItem('color')}
                      variant="outline"
                      onClick={onOpenAlert}
                    >
                      {locale['Decline']}
                    </Button>
                  </VStack>
                </HStack>
              )
            ) : (
              <HStack justifyContent={'flex-end'} mx={10}>
                <VStack justifyContent={'flex-end'} alignItems={'flex-end'}>
                  <Button
                    borderRadius="sm"
                    size="sm"
                    colorScheme={localStorage.getItem('color')}
                    variant="outline"
                    onClick={() => showDeclineHistory(true)}
                  >
                    Show Decline History
                  </Button>
                  <Button
                    borderRadius="sm"
                    size="sm"
                    colorScheme={localStorage.getItem('color')}
                    variant="outline"
                    onClick={onOpenRequest}
                    isLoading={loading}
                  >
                    Cancel Request
                  </Button>
                  <Button
                    mx={5}
                    borderRadius="sm"
                    size="sm"
                    colorScheme={localStorage.getItem('color')}
                    variant="outline"
                    onClick={onOpenParticipantView}
                  >
                    {locale['Delegate']}
                  </Button>
                  <Button
                    borderRadius="sm"
                    size="sm"
                    colorScheme={localStorage.getItem('color')}
                    variant="outline"
                    onClick={() => setShowForms(true)}
                  >
                    {'I Will Do It'}
                  </Button>
                </VStack>
              </HStack>
            )}
          </ModalBody>
          <ModalFooter>
            {showForms ? (
              <Flex w={'full'} justifyContent={'space-between'}>
                <Button
                  borderRadius="sm"
                  size="sm"
                  colorScheme={localStorage.getItem('color')}
                  variant="outline"
                  onClick={() => setShowForms(false)}
                >
                  {locale['Back']}
                </Button>
                <Button
                  borderRadius="sm"
                  size="sm"
                  colorScheme={localStorage.getItem('color')}
                  bg={localStorage.getItem('color')}
                  variant="solid"
                  onClick={onViewLifeCycle}
                >
                  {'View Status Life Cycle'}
                </Button>
              </Flex>
            ) : null}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent borderRadius="sm">
            <AlertDialogHeader fontSize="lg" fontWeight="400">
              {locale['Decline']}
            </AlertDialogHeader>
            <Divider />
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <Text>Are you sure you want to decline?</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                borderRadius="sm"
                size="sm"
                variant="outline"
                colorScheme={localStorage.getItem('color')}
                onClick={onCloseAlert}
              >
                {locale['Cancel']}
              </Button>
              <Button
                borderRadius="sm"
                size="sm"
                colorScheme={localStorage.getItem('color')}
                bg={localStorage.getItem('color')}
                variant="solid"
                onClick={onSubmitDecline}
                ml={3}
              >
                {locale['Submit']}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isOpenRequest}
        leastDestructiveRef={cancelRef}
        onClose={onCloseRequest}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent borderRadius="sm">
            <AlertDialogHeader fontSize="lg" fontWeight="400">
              {['Cancel Request']}
            </AlertDialogHeader>
            <Divider />
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <Text>{`Do you want to close ${activity.activity_title}?`}</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                borderRadius="sm"
                size="sm"
                variant="outline"
                colorScheme={localStorage.getItem('color')}
                onClick={onCloseRequest}
              >
                {locale['Cancel']}
              </Button>
              <Button
                borderRadius="sm"
                size="sm"
                colorScheme={localStorage.getItem('color')}
                bg={localStorage.getItem('color')}
                variant="solid"
                onClick={onCancelRequest}
                ml={3}
              >
                {locale['Submit']}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default WhatNextView
