import { createContext, useContext, useReducer } from 'react'
import { localizationConst } from '../utils/action.constant'

const LanguageContext = createContext()

export const useLanguageContext = () => useContext(LanguageContext)

const localeReducer = (state, action) => {
  switch (action.type) {
    case localizationConst.SET_SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.selectedLanguage,
        locale: getLocales(action.data),
      }
    default:
      return state
  }
}

const getLocales = data => {
  var localeValues = data.reduce(
    (obj, item) =>
      Object.assign(obj, { [item.app_content_name]: item.translated_text }),
    {}
  )
  return localeValues
}

const initialState = {
  locale: {},
  selectedLanguage: { label: 'English', value: 1 },
}

export const LanguageContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(localeReducer, initialState)

  return (
    <LanguageContext.Provider value={{ state, dispatch }}>
      {children}
    </LanguageContext.Provider>
  )
}
