import { createContext, useContext, useReducer, useEffect } from 'react'
import { LS_LOGIN_MODE } from '../components/login/login.util'
import {
	dashboardConst,
	loginConst,
	sharedConst,
} from '../utils/action.constant'
import { AuthMode } from '../utils/common.util'
import {
	AUTH_C_CODE,
	AUTH_P_CODE,
	AUTH_SESSION_CHECK,
	AUTH_USER_CG_TOKEN_KEY,
	AUTH_BUCKET_NAME,
	AUTH_E_CODE,
} from '../utils/constant'

const AuthContext = createContext()

export const useAuthContext = () => useContext(AuthContext)

const authReducer = (state, action) => {
	switch (action.type) {
		case loginConst.LOGIN_IN_PROGRESS:
			return {
				...state,
				isLoading: true,
			}
		case loginConst.LOGIN:
			const { token, username, phoneDetail, countryCode, mobile, idToken } =
				action.payload
			if (AuthMode() === LS_LOGIN_MODE.EMAIL) {
				const email = action.payload.idToken.payload.email
				localStorage.setItem(AUTH_E_CODE, email)
			} else if (AuthMode() === LS_LOGIN_MODE.AZURE_AD) {
				const azemail = action.payload.idToken.payload.email
				localStorage.setItem(AUTH_E_CODE, azemail)
			} else if (AuthMode() === LS_LOGIN_MODE.OTP) {
				// TODO: For handling multiple countries base mobile parsing
				const cCode = phoneDetail.country //username.slice(1, 3);
				const mCode = phoneDetail.phone //username.slice(username.length - 10);
				localStorage.setItem(AUTH_C_CODE, cCode)
				localStorage.setItem(AUTH_P_CODE, mCode)
			} else {
				localStorage.setItem(AUTH_C_CODE, countryCode)
				localStorage.setItem(AUTH_P_CODE, mobile)
			}
			localStorage.setItem(AUTH_SESSION_CHECK, true)
			localStorage.setItem(AUTH_USER_CG_TOKEN_KEY, idToken.jwtToken)
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				...action.payload,
			}
		case loginConst.SAVE_USER_AUTH:
			const { asset_encryption_token_id, ...rest } = action.payload
			return {
				...state,
				authData: {
					...rest,
					asset_token_auth: asset_encryption_token_id,
					asset_encryption_token_id,
				},
			}
		case loginConst.LOGIN_IN_FAILED:
			const { error } = action.payload
			return {
				...state,
				isAuthenticated: false,
				isLoading: false,
				user: null,
				authData: {},
				error: error,
			}
		case loginConst.SET_BUCKET_NAME:
			localStorage.setItem(AUTH_BUCKET_NAME, action.bucket_name)
			return {
				...state,
				bucket_name: action.bucket_name,
			}
		case loginConst.LOGOUT:
			localStorage.clear()
			sessionStorage.clear()
			return {
				...state,
				isAuthenticated: false,
				isLoading: false,
				authData: {},
				user: null,
			}
		case dashboardConst.SCROLL_BOTTOM:
			return {
				...state,
				isBottomChg: !state.isBottomChg,
			}
		case sharedConst.DS_RECORD:
			return {
				...state,
				ds: action.ds,
			}
		default:
			return state
	}
}

const initialState = {
	isAuthenticated: false,
	isLoading: false,
	authData: {},
	user: null,
	isBottomChg: true,
	ds: null,
}

export const AuthContextProvider = ({ ds, children }) => {
	const [state, dispatch] = useReducer(authReducer, initialState)
	useEffect(() => {
		dispatch({ type: sharedConst.DS_RECORD, ds: ds })
	}, [ds])

	return (
		<AuthContext.Provider value={{ state, dispatch }}>
			{children}
		</AuthContext.Provider>
	)
}
