import { createContext, useContext, useReducer } from 'react'
import { smartFormConst } from '../utils/action.constant'

const SmartFormContext = createContext()

export const useSmartFormContext = () => useContext(SmartFormContext)

const smartFormReducer = (state, action) => {
	switch (action.type) {
		case smartFormConst.SET_FIELD_VALUE:
			let formFields = { ...state.fields }
			let fields = {}
			if (!state.fields.hasOwnProperty(action.formId)) {
				fields = {
					[action.fieldId]: action.value,
				}
				formFields[action.formId] = fields
			} else {
				fields = {
					...formFields[action.formId],
					[action.fieldId]: action.value,
				}
				formFields[action.formId] = fields
			}
			return {
				...state,
				fields: {
					...formFields,
				},
			}

		case smartFormConst.SET_FIELD_VALUES:
			return {
				...state,
				fields: {
					...action.fields,
				},
			}
		case smartFormConst.TO_REFRESH_NUM_VAL:
			return {
				...state,
				toReFreshNumVal: !state.toReFreshNumVal,
			}
		case smartFormConst.ON_MODEL_OPEN:
			return {
				...state,
				isModelOpen: true,
				modelActivityId: action.activityId,
			}
		case smartFormConst.SET_DELETE_ID:
			return {
				...state,
				deleteFeild: action.field,
			}

		case smartFormConst.ON_MODEL_CLOSE:
			return {
				...state,
				isModelOpen: false,
				modelActivityId: '',
			}
		default:
			return state
	}
}

const initialState = {
	deleteFeild: {},
	fields: {},
	toReFreshNumVal: true,
	isModelOpen: false,
	modelActivityId: '',
}

export const SmartFormContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(smartFormReducer, initialState)

	return (
		<SmartFormContext.Provider value={{ state, dispatch }}>
			{children}
		</SmartFormContext.Provider>
	)
}
