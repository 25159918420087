import { createContext, useContext, useReducer } from 'react';
import { smartFormConst } from '../utils/action.constant';

const FormOnFlyContext = createContext();

export const useFormOnFlyContext = () => useContext(FormOnFlyContext);

const smartFormReducer = (state, action) => {
  switch (action.type) {
    case smartFormConst.SET_FIELD_VALUE:
      let fields = {
        ...state.fields,
        [action.fieldId]: action.value,
      };
      return {
        ...state,
        fields: {
          ...fields,
        },
      };

    default:
      return state;
  }
};

const initialState = {
  fields: {},
};

export const FormOnFlyContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(smartFormReducer, initialState);

  return (
    <FormOnFlyContext.Provider value={{ state, dispatch }}>
      {children}
    </FormOnFlyContext.Provider>
  );
};
